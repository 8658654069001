@import "/src/styles/_variables";

footer {
  margin-bottom: $lg;
}

footer p {
  margin: 0;
}

footer .nav-item:first-of-type .nav-link {
  padding-left: 0;
}

footer .nav-item:last-of-type .nav-link {
  padding-right: 0;
}

footer a {
  color: $black !important;
}

footer a:hover {
  color: $blue !important;
}

footer .icon-bank {
  display: inline-block;
  // background: $gradient-white;
  height: 48px;
  width: 100px;
  padding: $xs;
  position: relative;
  border-radius: $sm;
  // box-shadow: $lightshadow;
  margin-left: $sm;
}

footer .icon-bank img {
  display: block;
  max-width: 80px;
  max-height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

footer .social-icon {
  width: 36px !important;
  height: 36px !important;
  margin-right: $md;
}

footer .social-icon:last-child {
  margin: 0;
}

@media screen and (max-width: 767px) {
  footer {
    font-size: 0.95em;
  }
}

@media screen and (max-width: 1199px) {
  footer .payment {
    display: none;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  footer {
    font-size: 1.2em;
  }
}
