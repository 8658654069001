@import "/src/styles/_variables";

#hero {
  // padding: $xl $lg;
  // aspect-ratio: 3/1.25;
  position: relative;
  // margin-top: $md;
  // margin-bottom: $xl;
  // background-image: linear-gradient(
  //     280deg,
  //     rgba(109, 184, 242, 0.01) 10%,
  //     rgba(26, 112, 187, 1) 60%
  //   ),
  //   url("./../../images/banner.jpg");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  // box-shadow: $lightshadow;
  color: $black;
  height: max-content;
  display: flex;
}

#hero .row {
  height: 100%;
}

#hero .title,
#hero h2 {
  color: $blue;
  font-weight: 700;
  font-size: 1.75rem;
  margin-top: 0;
  font-family: $montserrat;
}

#hero .description {
  font-size: 19px;
}

@media screen and (max-width: 767px) {
  #hero {
    aspect-ratio: unset;
    margin-top: $xl;
    margin-bottom: $sm;
  }

  #hero .title,
  #hero .seo {
    font-size: unset;
  }

  #hero .title,
  #hero h2 {
    font-size: 1.3em !important;
  }

  #hero .description {
    font-size: 0.95em;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #hero {
    margin: $xl 0;
  }

  #hero .title,
  #hero h2 {
    font-size: 1.4em;
  }

  #hero .description {
    font-size: 0.95em;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  #hero {
    margin: $xl 0;
  }

  #hero .title,
  #hero h2 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  #hero .description {
    font-size: 1.2em;
  }
}

#translate .title {
  color: $white;
}
