@import "/src/styles/_variables";

header {
  margin: 10px 0;
}

header a {
  text-decoration: none;
}

header .logo-text {
  font-size: 2.5em;
  font-weight: 700;
  color: $blue;
  line-height: normal;
  margin: 0;
  font-family: $montserrat;
}

header .logo-text span {
  color: $white;
}

header h1 {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-weight: normal;
  color: $grey;
}

header small {
  color: $blue;
  line-height: normal;
}

header .nav-link {
  color: $white;
  cursor: pointer;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0 0 0 0 $blue;
  padding: $sm 12px;
  margin: 0;
  font-size: 1.1em;
  font-weight: 600;
  font-family: $montserrat;
}

@media screen and (max-width: 991px) {
  header .sidebar {
    position: fixed;
    width: 100%;
    top: 15px;
    right: 10px;
    z-index: 8;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  header .nav-link {
    font-size: 1.2em;
    padding: $xs $sm;
  }
}

header .nav-link:hover {
  color: $blue;
}

header .nav-link:hover ion-icon {
  color: $white;
}

header .cta ion-icon {
  color: $white;
}

@media screen and (max-width: 767px) {
  header .logo-text {
    font-size: 2em;
  }

  header small {
    font-size: 0.7em;
  }

  header .cta {
    display: none;
  }
}

header .sidebar {
  font-size: 1.7em;
  margin-right: 3px;
  color: $blue;
}

.open-sidebar {
  width: 75vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background: $gradient-blue;
  z-index: 9;
  padding: $lg;
  -webkit-animation: slideIn 0.5s forwards;
  -moz-animation: slideIn 0.5s forwards;
  animation: slideIn 0.5s forwards;
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slideIn {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}

.open-sidebar .close {
  font-size: 1.7em;
  margin-right: 3px;
  color: $white;
  float: right;
  cursor: pointer;
  margin-bottom: $md;
}

.open-sidebar .nav-item {
  display: block;
  color: $white;
  padding: $sm 0;
  text-align: center;
  clear: both;
  border: none;
  font-family: $montserrat;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.open-sidebar .nav-item:hover {
  border: none;
}
