@import "/src/styles/_variables";

.flip-card {
  aspect-ratio: 1/1;
  perspective: 1000px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-family: $montserrat;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card .info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: $sm;
  border-radius: $md;
}

.flip-card-front {
  box-shadow: $lightshadow;
  background-color: $blue;
  background-size: cover;
  background-repeat: no-repeat;
}

#jaminan .flip-card-front {
  background-image: linear-gradient(
      to bottom,
      rgba(22, 100, 226, 0.1) 10%,
      rgba(38, 109, 225, 0.6) 60%
    ),
    url("./../../images/features/jaminan.jpeg");
  background-size: contain;
}

#keahlian .flip-card-front {
  background-image: linear-gradient(
      to bottom,
      rgba(22, 100, 226, 0.1) 10%,
      rgba(38, 109, 225, 0.6) 60%
    ),
    url("./../../images/features/keahlian.jpeg");

  background-position: right;
}

#kualitas .flip-card-front {
  background-image: linear-gradient(
      120deg,
      rgba(22, 100, 226, 0.1) 10%,
      rgba(38, 109, 225, 0.6) 60%
    ),
    url("./../../images/features/kualitas.jpeg");
}

#manusia .flip-card-front {
  background-image: linear-gradient(
      120deg,
      rgba(22, 100, 226, 0.1) 10%,
      rgba(38, 109, 225, 0.6) 60%
    ),
    url("./../../images/features/manusia.jpeg");
}

#rahasia .flip-card-front {
  background-image: linear-gradient(
      120deg,
      rgba(22, 100, 226, 0.1) 10%,
      rgba(38, 109, 225, 0.6) 60%
    ),
    url("./../../images/features/rahasia.jpeg");
  background-position: right;
}

#price .flip-card-front {
  background-image: linear-gradient(
      120deg,
      rgba(22, 100, 226, 0.1) 10%,
      rgba(38, 109, 225, 0.6) 60%
    ),
    url("./../../images/features/price.jpeg");
  background-position: bottom;
}

.flip-card .title {
  font-weight: 700;
  color: $white !important;
  font-size: 1.2em;
}

.flip-card-back {
  box-shadow: $lightshadow;
  color: white;
  transform: rotateY(180deg);
  background: $gradient-blue;
}

/* Page */
#features.page .card {
  margin-bottom: $md;
}

#features.page .card-img-top {
  object-fit: cover;
  aspect-ratio: 16/9;
  border-top-left-radius: $md;
  border-top-right-radius: $md;
}

#features.page .card .section-title {
  font-size: 1.1em !important;
  margin-bottom: $md !important;
}

@media screen and (max-width: 767px) {
  .flip-card .title {
    font-size: 1em;
  }

  #features.page .card .section-title {
    font-size: 0.9em !important;
  }

  #features.page .card-body {
    font-size: 0.95em !important;
  }
}
