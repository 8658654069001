@import "/src/styles/_variables";

#harga .title {
  color: $grey;
  font-size: 1.25rem;
  font-weight: bold;
  font-family: $montserrat;
}

#harga small {
  text-transform: uppercase;
}

#harga .price {
  font-size: calc(1rem + 0.5vw);
  font-weight: bold;
  font-family: $montserrat;
}

#harga .slash {
  color: $red;
  font-size: 1.1em;
  text-decoration: line-through;
}

#harga .card[data-ribbon] {
  --d: 6px; /* folded part */
  --c: #e52b50; /* color */
  --f: 16px; /* ribbon font-size */
}

#harga .card[data-ribbon]::before {
  content: attr(data-ribbon);
  position: absolute;
  font-size: var(--f);
  top: 0;
  right: 0;
  transform: translate(29.29%, -100%) rotate(45deg);
  color: #fff;
  text-align: center;
  border: 1px solid transparent;
  border-bottom: 0;
  transform-origin: bottom left;
  padding: 5px 35px calc(var(--d) + 5px);
  background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d)
    no-repeat var(--c);
  background-clip: padding-box;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    calc(100% - var(--d)) calc(100% - var(--d)),
    var(--d) calc(100% - var(--d)),
    0 100%
  );
  mask: linear-gradient(
        135deg,
        transparent calc(50% - var(--d) * 0.707),
        #fff 0
      )
      bottom left,
    linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0)
      bottom right;
  -webkit-mask: linear-gradient(
        135deg,
        transparent calc(50% - var(--d) * 0.707),
        #fff 0
      )
      bottom left,
    linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0)
      bottom right;
  mask-size: 300vmax 300vmax;
  -webkit-mask-size: 300vmax 300vmax;
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
}

.flag {
  display: inline-block;
  box-shadow: $lightshadow;
  border-radius: 999px;
  position: relative;
  margin-right: $sm;
  margin-bottom: $sm;
}

.flag:first-of-type {
  margin-left: 0;
}

.flag:last-of-type {
  margin-right: 0;
}

.icon-flag {
  height: 24px;
  border: 1px solid $lightgrey;
}

@media screen and (max-width: 767px) {
  .card {
    margin-bottom: $md;
  }

  #harga .price {
    margin-top: $xs;
    font-size: 1.5em;
  }
}

// #harga.page .card {
//   text-align: left;
// }

// #harga.page .owl-carousel .card {
//   text-align: center;
//   margin: $md 0;
//   padding: 0;
// }

// #harga.page .card-title {
//   background: $gradient-blue;
//   color: $white;
//   border-top-right-radius: $sm;
//   border-top-left-radius: $sm;
//   padding: $sm 0 $xs 0;
//   margin-bottom: 0;
// }

// #harga.page .card-title h6 {
//   font-size: 1.2em;
// }

// #harga.page p {
//   margin-bottom: $xs;
// }

// #harga.page .price {
//   margin-bottom: $sm;
//   color: $blue;
//   background-color: $blue;
//   background-image: $gradient-darkblue;
//   background-size: 100%;
//   background-clip: text;
//   -webkit-background-clip: text;
//   -moz-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   -moz-text-fill-color: transparent;
//   text-shadow: $inset;
//   font-weight: 800;
//   font-size: 1.2em;
// }

// #harga.page .btn {
//   display: block;
//   margin: $sm auto auto auto;
// }
