@import "/src/styles/_variables";

#testimonials {
  font-family: $sourcesans;
  font-size: 18px;
}

.testimoni .image {
  aspect-ratio: 1/1;
  width: 25%;
  margin: auto;
  margin-bottom: $sm;
  border-radius: 999px;
  background-position: center;
  background-size: cover;
  box-shadow: $lightshadow;
}

#testimonials .owl-item:nth-child(1) .image {
  background-image: linear-gradient(
      120deg,
      rgba(22, 100, 226, 0.1) 10%,
      rgba(38, 109, 225, 0.6) 60%
    ),
    url("./../../images/testimonials/01.jpg");
}

#testimonials .owl-item:nth-child(2) .image {
  background-image: linear-gradient(
      120deg,
      rgba(22, 100, 226, 0.1) 10%,
      rgba(38, 109, 225, 0.6) 60%
    ),
    url("./../../images/testimonials/02.jpeg");
}

#testimonials .owl-item:nth-child(3) .image {
  background-image: linear-gradient(
      120deg,
      rgba(22, 100, 226, 0.1) 10%,
      rgba(38, 109, 225, 0.6) 60%
    ),
    url("./../../images/testimonials/03.jpg");
}

.testimoni .name {
  color: $blue;
  background-color: $blue;
  background-image: $gradient-blue;
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  // text-shadow: $inset;
  font-weight: bold;
  font-family: $montserrat;
  margin-bottom: $xs;
  display: block;
}

.testimoni .city {
  color: $grey;
  font-size: 0.8em;
  text-transform: uppercase;
}

.testimoni .LinesEllipsis {
  font-size: 0.85em;
}
