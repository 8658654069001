@import "/src/styles/_variables";

#kontak {
  margin-bottom: $lg;
  color: $white;
}

#kontak .box {
  background: $gradient-blue;
  padding: $lg;
  border-radius: $md;
  box-shadow: $lightshadow;
}

#kontak ion-icon {
  padding-top: $md;
  margin-right: $md;
  margin-bottom: $sm;
  background-color: white;
  color: $blue;
  padding: $sm;
  font-size: 1.7em;
  border-radius: 999px;
}

#kontak span {
  font-family: $montserrat;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  #kontak {
    font-size: 0.95em;
  }

  #kontak .box {
    padding: $md;
  }

  #kontak span {
    font-size: 0.85em;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #kontak span {
    font-size: 0.8em;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  #kontak {
    font-size: 1.2em;
  }
}
