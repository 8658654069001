@import "/src/styles/_fonts";
@import "/src/styles/_variables";
@import "/src/styles/_buttons";

body {
  font-family: $sourcesans;
  color: $black;
  background: $lightblue;
  background-image: url("../images/bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

p,
span {
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.section-title {
  margin-bottom: $lg !important;
  color: $blue !important;
  font-weight: 700 !important;
  font-size: calc(1.3rem + 0.6vw) !important;
  font-family: $montserrat;
  // background-color: $blue !important;
  // background-image: $gradient-blue !important;
  // background-size: 100% !important;
  // background-clip: text !important;
  // -webkit-background-clip: text !important;
  // -moz-background-clip: text !important;
  // -webkit-text-fill-color: transparent !important;
  // -moz-text-fill-color: transparent !important;
  // text-shadow: $inset;
}

@media screen and (max-width: 767px) {
  .section-title {
    font-size: 1.2em !important;
  }
}

.widget,
.page {
  margin-top: $xl;
}

@media screen and (max-width: 767px) {
  .widget,
  .page {
    margin-top: $lg !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .widget,
  .page {
    margin-top: $xl !important;
  }
}

.card {
  text-align: center;
  border-radius: $md;
  border: none;
  box-shadow: $lightshadow;
  // padding: $sm;
}

ion-icon {
  vertical-align: middle;
  display: inline-block;
}

.reverse {
  unicode-bidi: bidi-override;
  direction: rtl;
}

.blue {
  color: $blue;
}

.seo {
  font-weight: unset;
  font-size: 1em;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: unset;
}

h3.seo,
h4.seo {
  font-weight: 700 !important;
  // color: $white;
}
