@import "/src/styles/_variables";

.faq-row-wrapper,
.styles_faq-row-wrapper__3vA1D {
  background: none !important;
}

.faq-title {
  border: none !important;
}

.faq-title h2 {
  margin-bottom: $md !important;
  color: $blue !important;
  // background-color: $blue !important;
  // background-image: $gradient-blue !important;
  // background-size: 100% !important;
  // background-clip: text !important;
  // -webkit-background-clip: text !important;
  // -moz-background-clip: text !important;
  // -webkit-text-fill-color: transparent !important;
  // -moz-text-fill-color: transparent !important;
  // text-shadow: $inset;
  font-weight: 700 !important;
  font-size: calc(1.3rem + 0.6vw) !important;
  font-family: $montserrat;
}

.faq-row {
  background-color: $white;
  box-shadow: $lightshadow;
  margin-bottom: $sm;
  border-radius: $xs;
}

#home .faq-row:nth-child(n + 6) {
  display: none;
}

.row-title {
  padding-left: $md !important;
  color: $blue !important;
  font-family: $montserrat;
}

.row-title-text {
  font-size: 0.9em;
  font-weight: 700;
}

.row-content-text {
  border-top: 1px solid $lightgrey;
  padding: $sm $md $md $md !important;
}

@media screen and (max-width: 767px) {
  .faq-title h2 {
    font-size: 1.2em;
  }

  .row-title-text {
    font-size: 0.8em;
    font-weight: bold;
  }

  .row-content-text {
    font-size: 0.95em !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .row-content-text {
    font-size: 1.2em;
  }
}
