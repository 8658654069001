// Colors
$black: #555555;
$blue: #1a70bb;
$darkblue: #0f4e71;
$lightblue: #cce0f8;
$grey: #aca4a4;
$lightgrey: #ccd4d4;
$white: #ffffff;
$red: #e52b50;
$darkred: #9e1b32;

// Fonts
$montserrat: "Montserrat", sans-serif;
$sourcesans: "Source Sans 3", sans-serif;

// Gradients
$gradient-white: radial-gradient(
  circle at 18.7% 37.8%,
  rgb(250, 250, 250) 0%,
  rgb(225, 234, 238) 90%
);
$gradient-lightblue: linear-gradient(
  to left bottom,
  rgb(255, 228, 230),
  rgb(204, 251, 241)
);
$gradient-blue: linear-gradient(120deg, #6eb9f2 0, #1a70bb);
$gradient-darkblue: linear-gradient(to right, #1a70bb 0, #0f4e71);
$gradient-orange: linear-gradient(180deg, #feab3a 0, #f35626);

// Shadow
$lightshadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
$inset: 0 1px 0 rgba(255, 255, 255, 0.4);

// Sizes
$xs: 5px;
$sm: 10px;
$md: 20px;
$lg: 40px;
$xl: 60px;
$xxl: 80px;
