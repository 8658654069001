@import "/src/styles/_variables";

.btn {
  background-image: $gradient-blue;
  color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.2em;
  transition: all 0.3s ease-in-out;
  box-shadow: $lightshadow;
}

.btn:after {
  opacity: 0;
  -moz-transition: opacity 5s ease-in-out;
  -webkit-transition: opacity 5s ease-in-out;
  -o-transition: opacity 5s ease-in-out;
  transition: opacity 5s ease-in-out;
}

.btn:hover {
  background-image: $gradient-orange;
}

.btn:hover::after {
  opacity: 1;
}
