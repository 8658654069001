@import "/src/styles/_variables";

#translate {
  background: $gradient-blue;
  border-radius: $sm;
  padding: $md;
  box-shadow: $lightshadow;
}

#translate .title {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-family: $montserrat;
}

@media screen and (max-width: 767px) {
  #translate {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  #translate {
    bottom: -100px;
  }
}

#translate select {
  border-radius: $xs;
  padding: 10px 20px;
  font-size: 1.1em;
}

#translate .cek-harga {
  background-image: $gradient-orange !important;
  width: 100% !important;
}

#translate .cek-harga span {
  margin: 0;
}
