@import "/src/styles/_variables";

#clients .item {
  aspect-ratio: 1/1;
  position: relative;
}

#clients .item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 1;
  transition: all 0.3s ease;
}

#clients .item img:hover {
  cursor: pointer;
  -webkit-filter: unset;
  filter: unset;
  opacity: 1;
}

#clients.page {
  margin-top: $xl !important;
}

#clients.page .item {
  // background: $white;
  padding: $md;
  border-radius: $md;
  border: 1px solid $lightblue;
  box-shadow: $lightshadow;
  margin-bottom: $md;
  transition: all 0.3s ease-in-out;
}

#clients.page .item:hover {
  transform: scale(1.1);
}

#clients.page .item img {
  width: 100%;
  filter: unset;
  opacity: 1;
}

#clients.page .item img:hover {
  cursor: pointer;
  -webkit-filter: unset;
  filter: unset;
}
